<template>
  <div class="w-100 h-100">
    <div class="d-flex justify-content-between mb-4">
      <div class="bao-editable-text">
        <!-- Dashboard title-->
        <bao-editable-text
          v-model="currentPage.name"
          ref="editableText"
          :disabled="!canModifyWidgetPage() || currentPage.static"
          :extra-style="editableTextExtraStyle"
          :show-pen-icon="true"
          @input-changed="title => updatePageTitle(title)"
        ></bao-editable-text>
        <!-- Shared Dashboard Owner -->
        <div class="dashboard-owner"
             v-if="!isUserCreator() && currentPageHasCreator"
        >
          <img src="/img/icons/user-icon.svg"
               class="user-icon mr-2"
          />
          <div id="owner-label"
               class="global-color-slate-80 text-nowrap"
          >
            {{staticText.ownedByText}}
            <span class="font-weight-bold">
              {{currentPage.created_by.full_name}}
            </span>
            <div>
              {{currentPage.created_by.email}}
            </div>
          </div>
        </div>
      </div>

      <!-- Page controls -->
      <div class="dashboard-page-controls ml-3">
        <b-dropdown v-if="!currentPage.static"
                    no-caret
                    class="mr-2"
                    right
        >
          <template #button-content>
            <img src="/img/icons/points-icon.svg"/>
          </template>

          <b-dropdown-item>
            <b-btn
              variant="btn-outline"
              :disabled="isFavorite"
              class="p-0 w-100 text-left"
              @click="showSetFavoriteWarning=true"
            >
              <img class="mr-1"
                   src="/img/icons/conversation-icon.svg"
              >
              <span>{{ staticText.showOnNewConversationPage }}</span>
            </b-btn>
          </b-dropdown-item>

          <b-dropdown-divider></b-dropdown-divider>

          <b-dropdown-item>
            <b-btn
              variant="btn-outline"
              class="p-0 w-100 text-left"
              @click="duplicateCurrentPage"
            >
              <i class="fas fa-clone mr-1 global-color-slate-80"></i>
              <span>{{ staticText.duplicateDashboard }}</span>
            </b-btn>
          </b-dropdown-item>

          <b-dropdown-divider v-if="canModifyWidgetPage()"></b-dropdown-divider>

          <b-dropdown-item v-if="canModifyWidgetPage()">
            <bao-delete-button
              :id="'widget-page-delete-btn-' + currentPage.id"
              :label="staticText.deleteDashboard"
              :url="'/api/widgetpages/'+ currentPage.id"
              icon="fa fa-trash global-color-slate-80"
              variant="btn-outline"
              extra-class="p-0 w-100 text-left"
              @deleted="handleCurrentPageDeleted(currentPage)"
            >
            </bao-delete-button>
          </b-dropdown-item>
        </b-dropdown>

        <b-btn
          v-if="!currentPage.static && isUserCreator()"
          variant="secondary"
          class="mr-1"
          @click="openPermissionModal=true"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" v-bind:class="'svg-icon mr-1 fill-slate-80'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M503.691 189.836L327.687 37.851C312.281 24.546 288 35.347 288 56.015v80.053C127.371 137.907 0 170.1 0 322.326c0 61.441 39.581 122.309 83.333 154.132 13.653 9.931 33.111-2.533 28.077-18.631C66.066 312.814 132.917 274.316 288 272.085V360c0 20.7 24.3 31.453 39.687 18.164l176.004-152c11.071-9.562 11.086-26.753 0-36.328z"/></svg>

          {{ staticText.shareWidget }}
        </b-btn>

        <b-btn
          v-if="!currentPage.static && canModifyWidgetPage()"
          variant="secondary"
          @click="showWidgetSidebar"
        >
          <img src="/img/icons/union.svg"
               class="mr-1"
          />
          {{ staticText.addWidget }}
        </b-btn>
      </div>
    </div>

    <!--Displaying widgets on the current page -->
    <div v-if="!widgetsAvailable"
         class="row no-widget-placeholder"
    >
      <bao-dashboard-no-widget-display
        :widget-page="currentPage"
        @add-widget="showWidgetSidebar"
      ></bao-dashboard-no-widget-display>
    </div>
    <draggable
      v-else
      v-model="widgets"
      v-bind="dragOptions"
      :handle="canModifyWidgetPage() ? '.cursor-pointer': '.'"
      @start="onClone($event)"
    >
      <transition-group tag="div"
                        type="transition"
                        :class="renderSingleColumn ? 'flex' : 'widgets-container'"
      >
        <div v-for="(widget, index) in widgets"
             :key="`${index}-${widget.id}`"
             class="widget-item-container"
        >
          <component
            :is="widget.component"
            v-bind="{widget}"
          ></component>
        </div>
      </transition-group>
    </draggable>

    <div v-if="currentPage.id">
      <!-- Widgets creation sidebar -->
      <div v-if="!openConfiguratorView">
        <bao-widget-sidebar></bao-widget-sidebar>
      </div>

      <!-- Widgets configuration modal -->
      <div v-else>
        <bao-widget-configuration-modal></bao-widget-configuration-modal>
      </div>
    </div>

    <!--  modals -->
    <b-modal id="set-favorite-warning-modal"
             v-model="showSetFavoriteWarning"
             :title="staticText.AttentionText"
             :hide-header-close="true"
             :no-close-on-backdrop="true"
             :no-close-on-esc="true"
             :cancel-title="staticText.cancelText"
             :ok-title="okTitle"
             :cancel-variant="'primary'"
             :ok-variant="'secondary'"
             @ok="setFavorite(currentPage.id)"
    >
      <div class="d-flex justify-content-center mt-3">
        <img id="alert-icon-202103210125"
             src="/img/icons/exclamation-triangle-solid.svg"
             height="50px"
             class="mr-3"
        />
        <span>
          {{ favoritePageWarningText }}
        </span>

      </div>

    </b-modal>

    <bao-dashboard-permission-modal
      v-if=openPermissionModal
      :widget-page="currentPage"
      @input="updateCurrentPage"
      @close="openPermissionModal=false"
    >
    </bao-dashboard-permission-modal>
  </div>
</template>

<script>
import BaoDashboardPermissionModal from "@/apps/dashboard/BaoDashboardComponents/BaoDashboardPermissionModal"
import BaoDashboardNoWidgetDisplay from "@/apps/dashboard/BaoDashboardComponents/BaoDashboardNoWidgetDisplay"
import BaoDeleteButton from "@/apps/base/BaoDeleteButton"
import BaoWidgetSidebar from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetSidebar"
import BaoWidgetConfigurationModal from "@/apps/dashboard/BaoWidgetConfigurationModal"
import draggable from "vuedraggable"
import { mapActions, mapGetters, mapMutations } from "vuex"
import vClickOutside from "v-click-outside"
import { baseUrl } from "@/apps/dashboard"
import BaoEditableText from "@/apps/base/BaoEditableText"

export default {
  name: "BaoDashboardPageDisplay",
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    BaoDashboardNoWidgetDisplay,
    BaoDashboardPermissionModal,
    BaoDeleteButton,
    BaoWidgetSidebar,
    BaoWidgetConfigurationModal,
    BaoEditableText,
    draggable
  },
  data () {
    return {
      staticTextDefault: {
        addWidget: "Add Widget",
        showOnNewConversationPage: "Show on New Conversation",
        deleteDashboard: "Delete Dashboard",
        setFavoriteWarningText: "The selected dashboard and its widgets will be shown on your “New Conversation” page. Do you wanna proceed?",
        replaceFavoriteWarningText: "The currently shown dashboard on the “New Conversation” page will be replaced. Are you sure?",
        AttentionText: "Attention",
        proceedText: "Proceed",
        applyText: "Apply",
        cancelText: "Cancel",
        shareWidget: "Share",
        nonUniqueErrorTitle: "Dashboard name not unique",
        nonUniqueErrorMessage: "A dashboard with this name already exists. Please assign another name.",
        ownedByText: "Owned by",
        duplicateDashboard: "Duplicate Dashboard"
      },
      showSetFavoriteWarning: false,
      openPermissionModal: false
    }
  },
  computed: {
    ...mapGetters({
      currentPage: "dashboardStore/getCurrentPage",
      widgetsAvailable: "dashboardStore/widgetsAvailable",
      favoritePage: "dashboardStore/getFavoritePage",
      isUserCreator: "dashboardStore/isUserCreator",
      canModifyWidgetPage: "dashboardStore/canModifyWidgetPage",
      getDefaultDashboard: "dashboardStore/getDefaultDashboard",
      openConfiguratorView: "dashboardStore/openConfiguratorView",
      loadedAnalyticsPages: "dashboardStore/getLoadedAnalyticsPages",
      getWidgets: "dashboardStore/getWidgets",
      currentUserIsAdminOrSuperuser: "auth/isAdminOrSuperUser"
    }),
    currentPageHasCreator () {
      return !!this.currentPage && !!this.currentPage.created_by
    },
    widgets: {
      get () {
        return this.getWidgets
      },
      set (reOrderedItems) {
        this.setNewWidgetsOrder(reOrderedItems)
      }
    },
    dragOptions () {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
        forceFallback: true
      }
    },
    renderSingleColumn () {
      return this.currentPage.id && this.currentPage.id === "playbookanalytics"
    },
    isFavorite () {
      return this.currentPage.is_favorite
    },
    favoritePageWarningText () {
      return this.favoritePage ? this.staticText.replaceFavoriteWarningText : this.staticText.setFavoriteWarningText
    },
    okTitle () {
      return this.favoritePage ? this.staticText.proceedText : this.staticText.applyText
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    editableTextExtraStyle () {
      const dashboardTitleStyle = {
        "font-size": "36px",
        "font-weight": 700,
        "line-height": "43px",
        "max-width": "100%"
      }
      if (this.currentPage.static) {
        dashboardTitleStyle.width = "380px"
        dashboardTitleStyle.backgroundColor = "unset"
      }
      return dashboardTitleStyle
    }
  },
  methods: {
    ...mapMutations({
      showWidgetSidebar: "dashboardStore/showWidgetSidebar",
      updateCurrentPage: "dashboardStore/updateCurrentPage",
      deletePage: "dashboardStore/deletePage",
      showPage: "dashboardStore/showPage"
    }),
    ...mapActions({
      setNewWidgetsOrder: "dashboardStore/setNewWidgetsOrder",
      createFavoritePage: "dashboardStore/createFavoritePage",
      updateDashboard: "dashboardStore/updateDashboard",
      duplicateDashboard: "dashboardStore/duplicateDashboard"
    }),
    duplicateCurrentPage () {
      this.duplicateDashboard(this.currentPage).then(newPage => {
        this.$router.push(`/dashboard/${newPage.id}`)
        this.showPage(newPage)
      })
    },
    onClone (event) {
      // Problem: widgets with charts are not properly displayed during the drag.
      // Solution taken from this thread: https://github.com/SortableJS/Vue.Draggable/issues/664
      const itemCanvas = event.item.getElementsByTagName("canvas")[0]
      const cloneCanvas = document
        .getElementsByClassName("sortable-drag")[0]
        .getElementsByTagName("canvas")[0]

      if (itemCanvas && cloneCanvas) {
        const cloneCanvasContext = cloneCanvas.getContext("2d")
        cloneCanvasContext.drawImage(itemCanvas, 0, 0)
      }
    },
    setFavorite (pageId) {
      this.createFavoritePage(pageId)
    },
    updatePageTitle (newTitle) {
      this.hideGlobalToast()
      const updatedPage = { ...this.currentPage, name: newTitle }
      if (newTitle.toLowerCase() === this.currentPage.name.toLowerCase()) {
        // This is being updated incase the user is changing the case of certain characters in the previous title
        this.updateDashboard(updatedPage)
        return
      }
      if (this.validateNewTitle(newTitle)) this.updateDashboard(updatedPage)
      else {
        this.showErrorToast()
        this.$refs.editableText.revertText()
      }
    },
    validateNewTitle (newTitle) {
      const nonUniqueDashboards = this.loadedAnalyticsPages.filter(item => this.isUserCreator(item) && item.name.toLowerCase() === newTitle.toLowerCase())
      return !nonUniqueDashboards.length
    },
    showErrorToast () {
      const errorMessage = {
        title: this.staticText.nonUniqueErrorTitle,
        description: this.staticText.nonUniqueErrorMessage
      }
      this.showGlobalToast({
        status: "error",
        message: errorMessage
      })
    },
    handleCurrentPageDeleted (page) {
      this.deletePage(page.id)
      this.updateRoute(this.getDefaultDashboard)
    },
    updateRoute (page) {
      let url = baseUrl
      if (page) {
        url = `${url}/${page.id}`
      }
      // if the url is same as current route then do not reset as it results in navigation duplication error
      if (url === this.$route.path) return
      this.$router.push(url)
    }
  }
}

</script>

<style scoped lang="scss">
.widget-icon {
  height: 25px;
  width: 25px;
}

.d-flex-h-center {
  display: flex;
  justify-content: center;
}

b-sidebar-backdrop {
  opacity: 0.15;
}

.no-widget-placeholder {
  height: 65vh;
  display: flex;
  justify-content: center;
  align-content: center;
}

.widgets-container {
  column-count: 2;
}

.widget-item-container {
  column-fill: balance;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
}

.svg-icon {
  height: 14px;
  width: 14px;
}

.edit-icon {
  margin-top: -2px;
}

.subtext {
  font-size: 14px;
  color: $slate40;
  cursor: pointer;
  margin-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 400px;
}

.close-icon {
  position: absolute;
  margin-top: 3px;
  margin-left: 3px;
}

.subtext__margin {
  margin-left: 9px;
  margin-top: 1px;
}

.dashboard-owner {
  display: flex;
  padding-left: 9px;
  padding-top: 6px;
  min-width: 200px;
}

.user-icon {
  height: 15px;
  opacity: 0.8;
  margin-bottom: 3px;
}

.fill-slate-80 {
  fill: $slate80;
}

.bao-editable-text {
  max-width: calc(100% - 390px);
  overflow: hidden;
}

.dashboard-page-controls {
  display: flex;
  justify-content: flex-end;
  width: 374px;
  align-items: flex-start;
}
</style>
