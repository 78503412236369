<template>
  <div>
    <multiselect v-model="selectedOption"
                 :options="options"
                 :show-labels="showLabels"
                 :track-by="trackBy"
                 :label="label"
                 :placeholder="selectionPlaceholder"
                 :loading="loading"
                 :multiple="multiselect"
    ></multiselect>
  </div>
</template>

<script>
import axios from "axios"
import Multiselect from "vue-multiselect"

export default {
  name: "BaseSelection",
  components: {
    Multiselect
  },
  props: {
    value: null,
    showLabels: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: "name"
    },
    trackBy: {
      type: String,
      default: "id"
    },
    url: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      required: false,
      default: function () { return {} }
    },
    selectedOptionProperty: {
      type: String,
      default: null
    },
    selectedOptionPropertyVal: {
      default: null
    },
    multiselect: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      axios,
      loading: false,
      options: [],
      selectedOption: null,
      selectionPlaceholder: "",
      staticTextDefault: {
        placeholder: "Select"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  watch: {
    selectedOption () {
      this.$emit("input", this.selectedOption)
    },
    value () {
      this.selectedOption = this.value
    },
    query: {
      deep: true,
      handler (val, oldVal) {
        this.getOptions()
      }
    }
  },
  created () {
    this.setUp()
  },
  methods: {
    setUp () {
      this.selectedOption = this.value
      this.selectionPlaceholder = this.placeholder ? this.placeholder : this.staticText.placeholder
      this.getOptions()
    },
    getOptions () {
      this.loading = true
      return this.axios.get(this.url, { params: this.query }).then(response => {
        this.transformOptions(response)
        this.loading = false
      })
    },
    transformOptions (response) {
      this.options = response.data && response.data.results ? response.data.results : response.data
      if (this.selectedOptionProperty && this.selectedOptionPropertyVal) {
        this.selectedOption = this.options.find(
          item => item[this.selectedOptionProperty].toString() === this.selectedOptionPropertyVal.toString()
        )
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
