<template>
  <b-modal id="dashboard-permission-modal"
           v-model="openPermissionModal"
           :title="staticText.share"
           :ok-title="this.staticText.apply"
           :cancel-variant="'primary'"
           :ok-variant="'secondary'"
           ok-only
           @ok="share"
           @hidden="emitClose"
  >
    <div>
      <b-form-group>
        <b-form-radio
          v-for="(item, index) in permissionOptions"
          :key="index + item.value"
          v-model="sharingType"
          :value="item.value"
        >
          {{item.text}}
        </b-form-radio>
      </b-form-group>
      <base-selection
        v-if="sharingType==='custom'"
        id="filter-for-user-202228062148-permission"
        v-model="selectedUsers"
        :placeholder="staticText.selectUserPlaceholder"
        :multiselect="true"
        track-by="email"
        label="full_name"
        :url="'/api/users/get_group_members?is_active=true&id!=' + currentUser.pk"
      ></base-selection>
    </div>
  </b-modal>
</template>

<script>
import BaseSelection from "@/apps/base/BaseSelection"
import axios from "axios"
import { mapGetters } from "vuex"

export default {
  name: "BaoDashboardPermissionModal",
  components: { BaseSelection },
  props: {
    widgetPage: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      axios,
      staticTextDefault: {
        apply: "Apply",
        meText: "Only you can see this dashboard",
        teamText: "Your whole team can see this dashboard (read-only)",
        customText: "Selected users of your team can see this dashboard (read-only)",
        share: "Share",
        selectUserPlaceholder: "Select a User"
      },
      openPermissionModal: true,
      sharingType: "me",
      selectedUsers: []
    }
  },
  computed: {
    ...mapGetters({ currentUser: "auth/user" }),
    permissionOptions () {
      return [
        { value: "me", text: this.staticText.meText },
        { value: "team", text: this.staticText.teamText },
        { value: "custom", text: this.staticText.customText }
      ]
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  mounted () {
    this.setUp()
  },
  methods: {
    setUp () {
      if (this.widgetPage.shared_with) {
        this.sharingType = this.widgetPage.shared_with.type
        if (this.sharingType !== "team") {
          this.selectedUsers = this.getSelectedUsers()
        }
      }
    },
    getSelectedUsers () {
      return this.widgetPage.shared_with.users.filter(user => user.pk !== this.currentUser.pk)
    },
    share (event) {
      event.preventDefault()
      const url = `/api/widgetpages/${this.widgetPage.id}/share`
      const data = { sharing_type: this.sharingType }
      if (this.sharingType === "custom") {
        data.user_ids = this.selectedUsers.map(item => item.pk)
      }
      return this.axios.put(url, data).then(() => {
        this.$emit("input", { shared_with: { type: this.sharingType, users: this.selectedUsers } })
        this.openPermissionModal = false // close modal once input is emitted
      })
    },
    emitClose () {
      this.$emit("close")
    }
  }
}
</script>

<style scoped>

</style>
