<template>
  <div class="bao-dashboard">
    <div class="list-container">
      <!-- Display dashboard with its widgets -->
      <bao-dashboard-page-display
        v-if="!!currentPage &&  !!currentPage.id"
      ></bao-dashboard-page-display>

      <!-- Display no dashboards available -->
      <bao-dashboard-no-page-display
        v-else
        class="no-dashboard-placeholder"
      ></bao-dashboard-no-page-display>
    </div>

  </div>
</template>

<script>
import axios from "axios"
import { mapActions, mapGetters, mapMutations } from "vuex"
import BaoDashboardNoPageDisplay from "@/apps/dashboard/BaoDashboardComponents/BaoDashboardNoPageDisplay"
import BaoDashboardPageDisplay from "@/apps/dashboard/BaoDashboardComponents/BaoDashboardPageDisplay"
import { playbookAnalyticsPage } from "@/apps/dashboard/chartConfig"

export default {
  name: "BaoDashboard",
  components: {
    BaoDashboardNoPageDisplay,
    BaoDashboardPageDisplay
  },
  data () {
    return {
      playbookAnalyticsPage,
      axios,
      staticTextDefault: {
        playbookAnalytics: "Playbook Analytics"
      }
    }
  },
  computed: {
    ...mapGetters({
      currentPage: "dashboardStore/getCurrentPage",
      loadedAnalyticsPages: "dashboardStore/getLoadedAnalyticsPages",
      getDefaultDashboard: "dashboardStore/getDefaultDashboard",
      loadedDashboardsOnce: "dashboardStore/loadedDashboardsOnce"
    }),
    pageId () {
      return this.$route.params.page ? this.$route.params.page : null
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  watch: {
    "$route.path" () {
      this.showPage(this.getPageFromId(this.pageId))
    }
  },
  mounted () {
    this.setUp()
  },
  beforeRouteLeave (to, from, next) {
    /**
     * The currentPage is reset so that when dashboard page is loaded again next time,
     * then it must not show old data from currentPage
     */
    this.resetCurrentPage()
    next()
  },
  methods: {
    ...mapMutations({
      showPage: "dashboardStore/showPage",
      resetCurrentPage: "dashboardStore/resetCurrentPage"
    }),
    ...mapActions({
      loadAnalyticsPages: "dashboardStore/loadAnalyticsPages"
    }),
    setUp () {
      // if dashboards were already loaded once(when baoDashboardMenu is mounted) then do not reload it
      const promise = !this.loadedDashboardsOnce ? this.loadAnalyticsPages() : Promise.resolve()
      return promise.then(() => {
        this.showPage(this.getPageFromId(this.pageId))
      })
    },
    getPageFromId (id) {
      if (id === "playbookanalytics") return this.playbookAnalyticsPage(this.staticText)
      const page = this.loadedAnalyticsPages.find(item => "" + item.id === id)
      if (page) return page
      return this.getDefaultDashboard
    }
  }
}
</script>

<style scoped lang="scss">

.no-dashboard-placeholder {
  height: 70vh !important;
}

.list-container{
  height: 100%;
  overflow-y: auto;
}

</style>
