<template>
  <b-sidebar
    v-model="showWidgetSidebarVar"
    :shadow="true"
    width="35%"
    class="sidebar"
    right
    backdrop
    @hidden="closeWidgetSidebar"
  >
    <template #header>
      <div class="w-100">
        <img class="float-right"
             src="/img/icons/close-icon.svg"
             @click="closeWidgetSidebar"
        />
      </div>
    </template>
    <div class="pl-4 pr-3 pb-4 h-100">
      <!-- Widget type Selection -->
      <widget-type-selection />
    </div>
  </b-sidebar>
</template>

<script>
import WidgetTypeSelection from "@/apps/dashboard/BaoWidgetsBaseComponents/WidgetTypeSelection"
import { mapGetters, mapActions } from "vuex"

export default {
  name: "BaoWidgetSidebar",
  components: {
    WidgetTypeSelection
  },
  data () {
    return {
      staticTextDefault: {}
    }
  },
  computed: {
    ...mapGetters({
      showWidgetSidebar: "dashboardStore/showWidgetSidebar"
    }),
    showWidgetSidebarVar: {
      get () {
        return this.showWidgetSidebar
      },
      set (value) {
        return value
      }
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    ...mapActions({
      closeWidgetSidebar: "dashboardStore/closeWidgetSidebar"
    })
  }
}
</script>

<style scoped lang="scss">

.sidebar {
  position: relative;
  z-index: 9999;
}
</style>
