<template>
  <div class="w-100 h-100">
    <div class="no-dashboard-title">
      <h3 class="dashboard-title my-auto">
        {{ staticText.dashboard }}
      </h3>
    </div>

    <div class="h-100 d-flex flex-column justify-content-center">
      <div class="input-wrapper d-flex-h-center">
        <img src="/img/icons/vector.svg"
             class="widget-icon mb-2"
        />
      </div>
      <div class="font-size-20 font-weight-bold text-center">
        {{ staticText.noDashboardMessage }}
      </div>
      <p class="font-size-16 opacity-80 text-center">
        {{ staticText.addDashboardMessage  }}
      </p>
      <div class="d-flex-h-center w-100">
        <b-btn
          id="show-widget-sidebar-202103051221"
          variant="primary"
          @click="create"
        >
          {{ staticText.createDashboard}}
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from "vuex"
import { baseUrl } from "../index"

export default {
  name: "BaoDashboardNoPageDisplay",
  data () {
    return {
      staticTextDefault: {
        createDashboard: "Create dashboard",
        noDashboardMessage: "No dashboards available yet",
        addDashboardMessage: "Create a dashboard to display the widgets.",
        dashboard: "Dashboard",
        newDashboard: "New Dashboard"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    ...mapActions({
      createDashboard: "dashboardStore/createDashboard"
    }),
    create () {
      return this.createDashboard({ name: this.staticText.newDashboard }).then(newPage => {
        this.updateRoute(newPage)
      })
    },
    updateRoute (newPage) {
      this.$router.push(`${baseUrl}/${newPage.id}`)
    }
  }
}

</script>

<style scoped lang="scss">

.dashboard-title{
  font-weight: 700;
  font-size: 20px;
  color: $black;
  line-height: 22px;
}

.widget-icon {
  height: 25px;
  width: 25px;
}

.d-flex-h-center {
  display: flex;
  justify-content: center;
}

.no-dashboard-title{
  padding-top: 15px;
  padding-left: 8px;
  margin-bottom: 1.5rem;
}
</style>
