<template>
  <b-modal
    v-model="showWidgetSidebarVar"
    size="xl"
    :hide-header="true"
    :hide-footer="true"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    body-class="p-0"
    content-class="h-90vh"
    scrollable
    @hidden="closeWidgetSidebar"
  >
    <div class="h-100">
      <!-- Widget Configurations -->
      <component
        :is="widgetToConfigure.component"
        v-bind="{
          widget: widgetToConfigure,
          configView: true
        }"
      ></component>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
  name: "BaoWidgetSidebar",
  computed: {
    ...mapGetters({
      widgetToConfigure: "dashboardStore/getWidgetToConfigure",
      showWidgetSidebar: "dashboardStore/showWidgetSidebar"
    }),
    showWidgetSidebarVar () {
      return this.showWidgetSidebar
    }
  },
  methods: {
    ...mapActions({
      closeWidgetSidebar: "dashboardStore/closeWidgetSidebar"
    })
  }
}
</script>

<style scoped lang="scss">

</style>
